body{
    background-image: none !important;
}

body.login_register_page {
    /*background-color: white !important;*/
    background-color: #DFFBF6 !important;
    background-image: none !important;
}

.mobile{
    /*background-color: #DFFBF6 !important;*/
    background-color: white !important;
}

.dark-background{
    background-color: #177666 !important;
}

.dark-background h2{
    color: white !important;
}

#hero02{
    background-color: #77D2C1 !important;
}

#hero03{
    background-color: #77D2C1 !important;
}

#hero04{
    background-color: #77D2C1 !important;
}

#hero05{
    background-color: #77D2C1 !important;
}

.login_register_page {
    /*
    #app>aside {
        display: none !important;
    }

    #app>nav {
        display: none;
    }
    */
/*
    #app>div.content-wrapper.mb-4 {
        margin: 0 !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    @media screen and (min-width: 1400px) {
        #app>div.content-wrapper.mb-4 {
            max-width: 1280px;
        }
    }
*/
    /*
    #app>div.content-wrapper.mb-4>section {
        padding: 0 !important;
    }

    #app>div.content-wrapper.mb-4>section>div>div>div>div.row.page-content.justify-content-center {
        margin: 0 !important;
        padding: 0 !important;
    }

    @media screen and (max-width: 1024px) {
        #app>div.content-wrapper.mb-4>section>div>div>div>div.row.page-content.justify-content-center {
            margin-top: 3rem !important;
        }
    }

    @media screen and (max-width: 576px) {
        #app>div.content-wrapper.mb-4>section>div>div>div>div.row.page-content.justify-content-center {
            margin-left: 1.5rem !important;
            margin-right: 1.5rem !important;
        }
    }
    */

    #oldCarouselId>ol {
        display: none !important;
    }

    .icone-social-vittorio {
        color: var(--pw-gray);
    }

    .titolo-vittorio {
        color: var(--pw-primary-dark);
        font-size: 2em !important;
        margin-bottom: 0;
        font-weight: 600 !important;
    }

    .image-vet{
        border-radius: 10px;
        width:120px;
        height:100px;
        object-fit: cover;
    }

    .card-petwave{
        /*
        box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
        -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
        */
        border-radius: 20px;
        padding: 20px;
    }

    @media screen and (max-width: 576px) {
        .titolo-vittorio {
            font-size: 2em !important;
        }

        .titolo-chiedi-vet {
            font-size: 1.5em !important;
        }
    }

    .accettazioni-vittorio-grigio {
        font-family: "Poppins", sans-serif;
        font-size: 0.7rem;
        font-weight: 400;
        line-height: 1;
        color: var(--pw-gray);
        text-align: left;
    }

    @media screen and (max-width: 576px) {
        .accettazioni-vittorio-grigio {
            font-size: 0.9rem;
        }
    }

    .vittorio-landing{
        .accettazioni-vittorio-grigio {
            font-family: "Poppins", sans-serif;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: var(--pw-gray);
            text-align: left;
        }

        @media screen and (max-width: 576px) {
            .accettazioni-vittorio-grigio {
                font-size: 0.9rem;
            }
        }
    }

    .titolo-vittorio-viola {
        color: #93278f !important;
        font-weight: 400 !important;
        font-size: 1.6em !important;
    }

    .vittorio-social-button {
        color: #666666 !important;
        background-color: white !important;
        background: white !important;
        text-align: center !important;
        border-style: solid !important;
        border-color: #cccccc !important;
        border-width: 1px !important;
        font-size: 0.8rem !important;
        font-weight: 400 !important;
        line-height: 1.5 rem !important;
        border-radius: 40px !important;
        padding: 0.1rem 0.5rem;
    }

    .form-control {
        color: var(--pw-primary) !important;
        background-color: #e6e6e6 !important;
        background: #e6e6e6!important;
        text-align: left !important;
        border-style: solid !important;
        border-color: #e6e6e6 !important;
        border-width: 1px !important;
        font-size: 0.8rem !important;
        font-weight: 400 !important;
        line-height: 1.6 !important;
        border-radius: 40px !important;
        height: auto !important;
        padding: 0.225rem 0.5rem;
    }

    .vittorio-landing{
        .form-control{
            color: var(--pw-primary) !important;
            background-color: #e6e6e6 !important;
            background: white !important;
            text-align: left !important;
            border-style: solid !important;
            border-color: var(--pw-primary) !important;
            border-width: 1px !important;
            font-size: 1.2rem !important;
            font-weight: 400 !important;
            line-height: 1.6 !important;
            border-radius: 40px !important;
            height: auto !important;
            padding: 0.225rem 0.5rem;
        }
    }
    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: var(--pw-primary) !important;
        opacity: 1;
        /* Firefox */
        ;
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: var(--pw-primary) !important;
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: var(--pw-primary) !important;
    }

    .box-vittorio {
        background-color: #f2f2f2;
        border-radius: 10px;
        margin-top: -30px;
        min-height: 179px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media screen and (max-width: 1024px) {
        .box-vittorio {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .box-vittorio-pet-ask {
        background-color: #f2f2f2;
        border-radius: 10px;
        margin-top: -10px;
        min-height: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media screen and (max-width: 1024px) {
        .box-vittorio-pet-ask {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .box-vittorio-pdf {
        display: flex;
        align-items: center;
    }

    .box-vittorio-pdf h3 {
        color: white;
    }

    .btn-vittorio {
        border-radius: 30px;
        font-weight: 600;
        text-transform: uppercase;
        font-weight: 400 !important;
        line-height: 1.6 !important;
        height: auto !important;
        padding: 0.2rem 0.9rem !important;
    }

    .vittorio-landing{
        .btn-vittorio {
            border-radius: 30px;
            font-weight: 600;
            text-transform: uppercase;
            font-size: 1.2rem !important;
            font-weight: 400 !important;
            line-height: 1.6 !important;
            height: auto !important;
            padding: 0.2rem 0.9rem !important;
        }
    }

    .btn-vittorio-main {
        text-transform: uppercase;
    }

    .img-vittorio-40 {
        width: 40% !important;
    }

    .img-vittorio-45 {
        width: 45% !important;
        margin-top: -5% !important;
    }

    .img-vittorio-50 {
        width: 50% !important;
        margin-top: -10% !important;
    }

    .img-vittorio-55 {
        width: 55% !important;
        margin-top: -15% !important;
    }

    #oldCarouselId>ol>li {
        background-color: #93278f !important;
    }

    .testo-verde-vittorio-titolo {
        font-size: 2.0em !important;
        margin-bottom: 0 !important;
    }

    .testo-verde-vittorio-post-chiedi-vet {
        color: #93278f !important;
        font-size: 1.4em !important;
        margin-bottom: 0 !important;
    }

    .testo-grigio-vittorio-post-chiedi-vet {
        font-size: 1.0rem !important;
        color: #333333 !important;
        line-height: 1.2rem !important;
        letter-spacing: 0 !important;
    }

    @media screen and (min-width: 1366px) {
        .image-vet{
            border-radius: 10px;
            width:120px;
            height:70px;
            object-fit: cover;
        }
        .testo-verde-vittorio-titolo {
            font-size: 1.2em !important;
            margin-bottom: 0 !important;
        }
        .titolo-vittorio-viola {
            color: #93278f !important;
            font-weight: 400 !important;
            font-size: 1.1em !important;
        }
        .testo-verde-vittorio-post-chiedi-vet {
            color: #93278f !important;
            font-size: 1em !important;
            margin-bottom: 0 !important;
        }
        .testo-grigio-vittorio-post-chiedi-vet {
            font-size: 0.8rem !important;
            color: #333333 !important;
            line-height: 1.01rem !important;
            letter-spacing: 0 !important;
        }
    }

    @media screen and (min-width: 1550px) {
        .testo-vittorio-viola {
            padding-left: 10%;
            padding-right: 10%;
        }
        .image-vet{
            border-radius: 10px;
            width:120px;
            height:100px;
            object-fit: cover;
        }
        .testo-verde-vittorio-titolo {
            font-size: 1.4em !important;
            margin-bottom: 0 !important;
        }
        .titolo-vittorio-viola {
            color: #93278f !important;
            font-weight: 400 !important;
            font-size: 1.5em !important;
            line-height: 1.6em !important;
        }
        .testo-verde-vittorio-post-chiedi-vet {
            color: #93278f !important;
            font-size: 1.4em !important;
            margin-bottom: 0 !important;
        }
        .testo-grigio-vittorio-post-chiedi-vet {
            font-size: 1.0rem !important;
            color: #333333 !important;
            line-height: 1.2rem !important;
            letter-spacing: 0 !important;
        }
        .page-title{
            font-size: 2.2rem;
            font-weight: 500;
            color: #198472;
        }
    }

    @media screen and (min-width: 1750px) {
        .testo-vittorio-viola {
            padding-left: 15%;
            padding-right: 15%;
    }
}

    .testo-vittorio-viola {
        font-size: 1.3rem !important;
        color: #93278f !important;
        line-height: 1.7rem !important;
        letter-spacing: 0 !important;
    }

    @media screen and (max-width: 1024px) {
        .testo-verde-vittorio-titolo {
            font-size: 2.0em !important;
        }

        .testo-vittorio-viola {
            font-size: 1.1rem !important;
            line-height: 1.4rem !important;
            padding-left: 10%;
            padding-right: 10%;
        }
    }

    @media screen and (max-width: 768px) {
        .testo-verde-vittorio-titolo {
            font-size: 1.8em !important;
        }

        .testo-vittorio-viola {
            font-size: 1.3rem !important;
            line-height: 1.7rem !important;
        }
    }

    .testo-bianco-box-mail{
        font-size: 2rem !important;
    }

    @media screen and (max-width: 1600px) {
    .testo-bianco-box-mail{
        font-size: 1.8rem !important;
    }
}

    .form-check-input {
        position: inherit !important;
        margin-top: inherit !important;
        margin-left: inherit !important;
    }

    .vittorio-landing{
        .form-check-input {
            margin-top: 0 !important;
        }
    }

    .date-dropdowns select {
        color: var(--pw-primary) !important;
        background-color: #e6e6e6 !important;
        background: #e6e6e6 !important;
        text-align: left !important;
        border-style: solid !important;
        border-color: #e6e6e6 !important;
        border-width: 1px !important;
        font-size: 0.9rem !important;
        font-weight: 400 !important;
        line-height: 1.6 !important;
        border-radius: 40px !important;
    }

    #register-form select {
        color: var(--pw-primary) !important;
        background-color: #e6e6e6 !important;
        background: #e6e6e6 !important;
        text-align: left !important;
        border-style: solid !important;
        border-color: #e6e6e6 !important;
        border-width: 1px !important;
        font-size: 0.9rem !important;
        font-weight: 400 !important;
        line-height: 1.6 !important;
        border-radius: 40px !important;
    }

    .centratura-celle-condizioni {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 4%;
        min-height: 36.4px;
    }

    .centratura-celle-condizioni-pulsanti {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 36.4px;
    }

    .centratura-celle-condizioni-pulsanti>button {
        font-size: 0.7rem;
    }

    .carousel-inner {
        top: 0 !important;
        margin-bottom: 0 !important;
        background: #F2F2F3;
        border-radius: 20px;
    }

    #report-button-container {
        bottom: 1rem !important;
    }

    .btn-enter {
        display: inline-block;
    }

    .modal-header {
        border-bottom: none !important;
    }

    @media screen and (max-width: 500px) {
        .login-register-title{
            font-size: 1.4rem !important;
        }
        #app > nav{
            padding-left:0.5px !important;
            padding-right:4px !important;
        }
        #app > nav > ul > div{
            align-items: center;
        }
    }
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
    width: 100%;
    position: relative;
    overflow: hidden;
    padding: 50px 0 50px 0;
    background-image: url("/img/home/background-impronta-01.jpg");
    background-size: cover;
    background-position: center;
}

#hero::before {
    content: "";
    position: absolute;
    right: -100%;
    top: 20%;
    width: 250%;
    height: 200%;
    z-index: -1;
    background-color: #e6fffa;
    transform: skewY(135deg);
}

#hero h1 {
    margin: 0 0 10px 0;
    /*font-size: 48px;*/
    font-size: 44px;
    font-weight: 500;
    line-height: 56px;
    /*color: #198472;*/
    color: white;
    font-family: "Poppins", sans-serif;
}

#hero h2 {
    /*color: #28D1B4;*/
    color: #C3F5EC;
    margin-bottom: 20px;
    font-size: 20px;
}

#hero .download-btn {
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    font-size: 15px;
    display: inline-block;
    padding: 8px 24px 10px 46px;
    border-radius: 3px;
    transition: 0.5s;
    color: #fff;
    background: #0F5145;
    position: relative;
    min-width: 158px;
}

#hero .download-btn:hover {
    background: #21B9A5;
    text-decoration: none;
}

#hero .download-btn i {
    font-size: 20px;
    position: absolute;
    left: 18px;
    top: 3.5px;
}

#hero .download-btn+.download-btn {
    margin-left: 20px;
}


@media (max-width: 991px) {
    #hero {
        text-align: center;
    }

    #hero .download-btn+.download-btn {
        margin: 0 10px;
    }

    #hero .hero-img {
        text-align: center;
    }

    #hero .hero-img img {
        width: 60%;
    }
}

@media (max-width: 768px) {
    #hero h1 {
        font-size: 28px;
        line-height: 36px;
    }

    #hero h2 {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 30px;
    }

    #hero .hero-img img {
        width: 70%;
    }
}

@media (max-width: 575px) {
    #hero .hero-img img {
        width: 80%;
    }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
    padding: 60px 0;
    overflow: hidden;
}

.section-bg {
    background-color: #f2f5fa;
}

.section-title {
    text-align: center;
    padding-bottom: 30px;
}

.section-title h2 {
    font-size: 48px;
    font-weight: 500;
    margin-bottom: 20px;
    padding-bottom: 0;
    color: #1E9E88;
    font-family: "Poppins", sans-serif;
}

.section-title-30 h2 {
    font-size: 30px;
    font-weight: 800;
    margin-bottom: 20px;
    padding-bottom: 0;
    color: #1E9E88;
    font-family: "Poppins", sans-serif;
}

.section-title p {
    margin-bottom: 0;
}


/*--------------------------------------------------------------
# App Features
--------------------------------------------------------------*/
.features .content {
    padding: 30px 0;
}

.features .content .icon-box {
    margin-top: 25px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.features .content .icon-box h4 {
    font-size: 20px;
    font-weight: 700;
    /*
    margin: 5px 0 10px 60px;
    */
    margin: 0;
}

.features .content .icon-box i {
    font-size: 48px;
    float: left;
    color: #5777ba;
}

.features .content .icon-box p {
    font-size: 14px;
    color: #979aa1;
}

@media (max-width: 991px) {
    .features .image {
        text-align: center;
    }

    .features .image img {
        max-width: 80%;
    }
}

@media (max-width: 667px) {
    .features .image img {
        max-width: 100%;
    }
}